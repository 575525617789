import React, {useState} from 'react';
import HeroSection from '../components/HeroSection'
import { homeObjOg } from '../components/HeroSection/Data';
import InfoSection from '../components/InfoSection';
import { homeObjOne } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Services from '../components/Services';
import Footer from '../components/Footer';
import Catalog from '../components/CatalogSection'
import Partners from '../components/PartnerSections'


function Home ()  {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
          <Sidebar isOpen={isOpen} toggle={toggle}/>
          <Navbar toggle={toggle}/>
          <HeroSection {...homeObjOg} />
          <InfoSection {...homeObjOne} />
          <Services />
          <Catalog/>
          <Partners />
          <Footer />
        </>
    );
};

export default Home;