/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Carousel from 'react-grid-carousel'
import { useTranslation } from 'react-i18next';
import {
    CatalogContainer,
    CatalogWrapper,
    Column2,
    Column1,
    CatalogRow,
    TextWrapper,
    Subtitle,
    Carou,
    CatalogeH1
} from './CatalogElements'

import '../CatalogSection/catalog.css'

const Catalog = ({ p }) => {


    const { t } = useTranslation()

    return (
        <>
            <CatalogContainer dir="ltr" id='catalog'>
                <CatalogRow>
                    <Column1>
                        <TextWrapper>
                            <CatalogeH1>{t('catalog_topline')}</CatalogeH1>
                            <Subtitle>{t('catalog_subtitle')}</Subtitle>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <CatalogWrapper>
                             <Carou id="back"
                            > 
                            <Carousel
                                cols={6}
                                rows={3}
                                gap={1}
                                responsiveLayout={[
                                    {
                                      breakpoint: 1200,
                                      cols: 3
                                    },
                                    {
                                      breakpoint: 990,
                                      cols: 2
                                    },
                                    {
                                        breakpoint: 480,
                                        cols: 1,
                                        rows: 1
                                    }
                                  ]}
                                  mobileBreakpoint={200}
                                
                                containerStyle={{ background: 'transparent' }} loop
                            >
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/0v77.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/b423.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/e53n.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/nhon.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/vl64.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/kooi.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/iv1r.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/fc6s.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/gj22.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/1cei.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/wh3m.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/1dun.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/4ww9.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                <img width="100%" src="https://zupimages.net/up/21/23/57lm.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/0011.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage" />
                                </Carousel.Item>
                                <Carousel.Item>
                                <img width="100%" src="https://zupimages.net/up/21/23/t50f.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/yl3u.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                <img width="100%" src="https://zupimages.net/up/21/23/6lik.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/gr9p.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                <img width="100%" src="https://zupimages.net/up/21/23/ukzh.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>                                
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/i077.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/msnw.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/ddfa.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/ve7p.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/x872.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/nam6.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/bzxf.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/gywf.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/p71t.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/j4dw.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/j1nn.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/di1d.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/mt7s.png" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/uo36.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/977d.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://zupimages.net/up/21/23/oh1j.jpg" alt="SPS EXPORT - Pièces de rechange et pièces de personnalisation - Machine de Soufflage PET et PEHD, Soufflage par étirement, remplissage/Capsuleuse, Étiquetage, Moulerie, Soufflage"/>
                                </Carousel.Item>
                            </Carousel>
                           </Carou>
                        </CatalogWrapper>
                    </Column2>
                </CatalogRow>
            </CatalogContainer>
        </>
    );
};

export default Catalog;