import React from 'react';
import Video from '../../videos/video3.mp4';
import {useTranslation} from 'react-i18next'

import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroP, HeroImg, Img} from './HeroElements';


import '../HeroSection/hero.css'

const HeroSection = ({Logo, img}) => {
    const {t} = useTranslation()


    return (
        <HeroContainer >
            <HeroBg>
                <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
                <HeroImg>
                    <Img src={img} alt="SPS EXPORT logo"/>
                </HeroImg>
                <h1>{t('heroh1')}</h1>
                <h3>{t('heroh3')}</h3>
                <HeroP>
                    {t('heroP')}
                </HeroP>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
