import React from "react";
import Carousel, { autoplayPlugin, infinitePlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";


import {

    PartnerContainer,
    PartnerRow,
    Column1,
    Column2,
    TextWrapper,
    PartnerH1,
    Subtitle,
    PartnerWrapper,
    CarouPart
} from './PartnerElements'

import '../PartnerSections/partner.css'


import part1 from "../../images/partners/part1.png";
import part2 from "../../images/partners/part2.jpg";
import part4 from "../../images/partners/part4.png";
import part5 from "../../images/partners/part5.png";
import part6 from "../../images/partners/part6.png";
import part8 from "../../images/partners/part8.png";
import part9 from "../../images/partners/part9.jpg";
import part10 from "../../images/partners/part10.jpg";
import part11 from "../../images/partners/part11.jpg";
import part12 from "../../images/partners/part12.gif";
import part13 from "../../images/partners/part13.jpg";
import part14 from "../../images/partners/part14.png";
import part15 from "../../images/partners/part15.jpg";
import part16 from "../../images/partners/part16.jpg";
import part17 from "../../images/partners/part17.jpg";
import part18 from "../../images/partners/part18.jpg";
import part19 from "../../images/partners/part19.png";




import { useTranslation } from "react-i18next";

const Catalog = () => {

    const { t } = useTranslation()

    return (
        <>
            <PartnerContainer dir="ltr" id='partner'>
                <PartnerRow>
                    <Column1>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <PartnerH1>{t('partner_h1')}</PartnerH1>
                            <Subtitle>{t('partner_subtitle')}</Subtitle>
                        </TextWrapper>
                        <PartnerWrapper>
                            <CarouPart
                            >
                                <Carousel
                                plugins={[
                                    'infinite',
                                    'autoplay',
                                    {
                                        resolve: slidesToShowPlugin, autoplayPlugin, 
                                        options: {
                                            numberOfSlides: 4,
                                        }
                                    },
                                ]}
                                animationSpeed={2000}
                                breakpoints={{
                                    480: {
                                        plugins: [
                                            {
                                                resolve: slidesToShowPlugin, autoplayPlugin,
                                                options: {
                                                    numberOfSlides: 1,  
                                                }
                                            }
                                        ]
                                    }
                                }}
                                >
                                    <img class="partner" src={part1} alt="sps export - Partenaire" />
                                    <img class="partner" src={part2} alt="sps export - Partenaire" />
                                    <img class="partner" src={part4} alt="sps export - Partenaire"  />
                                    <img class="partner" src={part5} alt="sps export - Partenaire" />
                                    <img class="partner" src={part6} alt="sps export - Partenaire" />
                                    <img class="partner" src={part8} alt="sps export - Partenaire" />
                                    <img class="partner" src={part9} alt="sps export - Partenaire" />
                                    <img class="partner" src={part10} alt="sps export - Partenaire" />
                                    <img class="partner" src={part11} alt="sps export - Partenaire" />
                                    <img class="partner" src={part12} alt="sps export - Partenaire" />
                                    <img class="partner" src={part13} alt="sps export - Partenaire" />
                                    <img class="partner" src={part14} alt="sps export - Partenaire" />
                                    <img class="partner" src={part15} alt="sps export - Partenaire" />
                                    <img class="partner" src={part16} alt="sps export - Partenaire" />
                                    <img class="partner" src={part17} alt="sps export - Partenaire" />
                                    <img class="partner" src={part18} alt="sps export - Partenaire" />
                                    <img class="partner" src={part19} alt="sps export - Partenaire" />
                                </Carousel>
                            </CarouPart>
                        </PartnerWrapper>
                    </Column2>
                </PartnerRow>
            </PartnerContainer>
        </>
    );
};

export default Catalog;