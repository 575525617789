import React from 'react';
import Icon1 from '../../images/icon1.svg'
import Icon2 from '../../images/icon2.svg'
import Icon3 from '../../images/icon3.svg';
import Icon4 from '../../images/icon4.webp'
import Icon5 from '../../images/icon5.png'
import {useTranslation} from 'react-i18next'



import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';

const Services = () => {
  const {t} = useTranslation()


  return (
    <ServicesContainer id='services'>
      <ServicesH1>{t('servtitle')}</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} alt="situation d'urgence"/>
          <ServicesH2>{t('serv1')}</ServicesH2>
          <ServicesP>
            {t('serv1P')}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} alt="visite diagnostique"/>
          <ServicesH2>{t('serv2')}</ServicesH2>
          <ServicesP>
          {t('serv2P')}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} alt="maintenance planifié" />
          <ServicesH2>{t('serv3')}</ServicesH2>
          <ServicesP>
          {t('serv3P')}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4}  alt="PET/breuvages - pièces de rechange : soufflage par étirement, remplissage/capsuleuse, étiquetage."/>
          <ServicesH2>{t('serv4')}</ServicesH2>
          <ServicesP>
          {t('serv4P')}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} alt="Pièces de personnalisation - Pièces de personnalisation : Moulerie, Soufflage et Remplissage." />
          <ServicesH2>{t('serv5')}</ServicesH2>
          <ServicesP>
          {t('serv5P')}
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};


export default Services;
