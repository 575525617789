import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages';
import ContactPage from './pages/contact';
import {useTranslation} from 'react-i18next'
import cookie from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code:'fr'
  },
  {
    code: 'en',
    name: 'English',
    country_code:'gb'
  },
  {
    code: 'ar',
    name: 'العربية',
    country_code:'sa',
    dir: 'rtl'
  },
]

function App() {
  const currentLanguageCode = cookie.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/contact' component={ContactPage} exact/>
      </Switch>
    </Router>
  );
}

export default App;
