/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { useTranslation } from 'react-i18next'
import { animateScroll as scroll } from 'react-scroll';
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  GlobeIcon,
  Popup
} from './NavbarElements'
import '../Navbar/navbar.css'



const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const languages = [
    {
      code: 'fr',
      name: 'French',
      country_code: 'fr',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ]

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const { t } = useTranslation()

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav 
        scrollNav={scrollNav} 
        >
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/' dir="ltr" >{t('navlogo')}</NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='about'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >{t('about')}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='services'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >{t('services')}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='catalog'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >{t('catalog')}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  href="/partner"
                  to='partner'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >{t('partner')}
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to='/contact'>{t('contact')}</NavBtnLink>
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <GlobeIcon />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <span className="dropdown-item-text">{t('language')}</span>
                </li>
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <a
                      href=""
                      className={classNames('dropdown-item', {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code)
                      }}
                    >
                      <span
                        className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </NavBtn> 
          </NavbarContainer>
{/*           <Popup></Popup>  */}
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar
