import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Subtitle, 
} from './InfoElements' 

const InfoSection = ({lightBg, 
    id, 
    imgStart,
    darkText, 
}) => {

    const { t } = useTranslation()
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                        <TextWrapper>
                        <TopLine>{t('topLine')}</TopLine>
                        </TextWrapper>
                        <TextWrapper>
                            <Subtitle darkText={darkText}>{t('descL1')}</Subtitle>
                            <Subtitle darkText={darkText}>{t('descL12')}</Subtitle>
                            <Subtitle darkText={darkText}>{t('descL13')}</Subtitle>
                        </TextWrapper>
                        </Column1>
                        <Column2>
                        <TextWrapper>
                            <Subtitle darkText={darkText}>{t('descR1')}</Subtitle>
                            <Subtitle darkText={darkText}>{t('descR12')}</Subtitle>
                            <Subtitle darkText={darkText}>{t('descR13')}</Subtitle>
                        </TextWrapper>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    );
};

export default InfoSection;

