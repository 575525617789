import styled from 'styled-components';

export const PartnerContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: 
  linear-gradient(to bottom,  #010606 90%, #232a34 );

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const PartnerRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};
  /* Must use '\' css class \'' */

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col2' 'col1'` : `'col2 col2' 'col1 col1'`};
  }
`;

export const Column1 = styled.div`
  margin-top: 85px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 480px) {
    margin-top: 25px;
  }
`;

export const PartnerWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 3px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;


export const PartnerH1 = styled.h1`
  margin-bottom: 24px;
  font-size: 2.5rem;
  text-align: left;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#01bf71')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.0rem;
  }

`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: -40px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }

  @media screen and (max-width: 701px) {
    font-size: 16px;
  }

`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 60px;
  grid-area: col1;

  @media screen and (max-width: 768px){
    padding: 0 15px;
  }
`;




export const CarouPart = styled.div`
  border-radius: 100px;
  width: 1000px; 
  margin: auto; 
  padding: 50px;


  @media screen and (max-width: 480px){
    width: 300px;
    margin: auto;
    border-radius: 10px;

  }
`;


