import React from 'react'
// import {ScrollToTop}  from 'react-scroll/modules/mixins/animate-scroll';
import Contact from '../components/Contact';

const ContactPage = () => {
    return (
        <>
          {/* <ScrollToTop /> */}
          <Contact />
        </>
    );
};

export default ContactPage;
