import React, { useState } from 'react';

import {
  GlobalStyle,
  StyledFormWrapper,
  StyledForm,
  StyledButton,
  StyledError,
  StyledTextArea,
  StyledInput, 
  initalState,
  Icon
} from './ContactElements';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const [state] = useState(initalState);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    console.log('submitted!');
    console.log(state);
    
    if ( email && company && message) {
      sendFeedback("template_2ky2msc", {
        email,
        company,
        message,
      });

    } else {

      for (let key in state) {
        if (state[key] === '') {
          setError(`You must provide the ${key}`)
          return
        }
      }
      setError('');
      // const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      // const test = regex.test(state.email);
      // console.log(test);
    }

    console.log("Succeeded!!!")
  };

  const sendFeedback = (templateId, variables) => {
    window.emailjs
    .send("service_q6gn48t", templateId, variables)
    .then((res) => {
      console.log("succeeded!!!")
      setEmail("");
      setCompany("");
      setMessage("")
    })
  }

  const {t} = useTranslation()

  return (
    <>
      <GlobalStyle>
      </GlobalStyle>
      <Icon dir="ltr" to='/'>{t('navlogo')}</Icon>
      <StyledFormWrapper dir="ltr" >
        <StyledForm onSubmit={handleSubmit}>
          <h4>Contact Us</h4>
          <p>dezfefezfez </p>
          <label htmlFor="email">Email</label>
          <StyledInput
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="company">Company</label>
          <StyledInput
            type="text"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <label htmlFor="message">Message</label>
          <StyledTextArea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {error && (
            <StyledError>
              <p>{error}</p>
            </StyledError>
          )}
          <StyledButton type="submit">Send Message</StyledButton>
        </StyledForm>
      </StyledFormWrapper>
    </>
  );
}
export default ContactPage;