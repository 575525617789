/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
    SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu,
    SidebarLink, SidebarBtnWrap, SidebarRoute
} from './SidebarElements'

import {
    GlobeIcon
} from '../Navbar/NavbarElements'


import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'


const Sidebar = ({ isOpen, toggle }) => {

    const { t } = useTranslation()

    const languages = [
        {
          code: 'fr',
          name: 'French',
          country_code: 'fr',
        },
        {
          code: 'en',
          name: 'English',
          country_code: 'gb',
        },
        {
          code: 'ar',
          name: 'العربية',
          dir: 'rtl',
          country_code: 'sa',
        },
      ]
    
      const currentLanguageCode = cookies.get('i18next') || 'en'    
    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        {t('about')}
                    </SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>
                        {t('services')}
                    </SidebarLink>
                    <SidebarLink to="catalog" onClick={toggle}>
                        {t('catalog')}
                    </SidebarLink>
                    <SidebarLink to="partner" onClick={toggle}>
                        {t('partner')}
                    </SidebarLink>
                </SidebarMenu>
                <SidebarBtnWrap>
                    <SidebarRoute to="/contact">{t('contact')}</SidebarRoute>
                      <button
                        className="btn btn-link dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <GlobeIcon />
                     </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <span className="dropdown-item-text">{t('language')}</span>
                        </li>
                        {languages.map(({ code, name, country_code }) => (
                            <li key={country_code}>
                                <a
                                    href="#"
                                    className={classNames('dropdown-item', {
                                        disabled: currentLanguageCode === code,
                                    })}
                                    onClick={() => {
                                        i18next.changeLanguage(code)
                                    }}
                                >
                                    <span
                                        className={`flag-icon flag-icon-${country_code} mx-2`}
                                        style={{
                                            opacity: currentLanguageCode === code ? 0.5 : 1,
                                        }}
                                    ></span>
                                    {name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </SidebarBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
